import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1500.000000 1500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)">
					<path d="M2843 9708 c3 -13 78 -212 167 -443 163 -424 233 -608 385 -1010 46
-121 113 -299 150 -395 37 -96 97 -256 135 -355 37 -99 82 -217 100 -262 l32
-83 524 0 524 0 0 23 c0 12 -14 152 -31 310 -17 159 -28 294 -26 301 3 8 30
15 67 19 34 3 63 7 66 9 2 3 -28 88 -67 189 -38 101 -106 279 -151 394 -44
116 -115 302 -158 415 -43 113 -119 313 -170 445 -50 132 -110 291 -133 353
l-41 112 -689 0 -689 0 5 -22z"/>
					<path d="M5826 9653 c-26 -71 -122 -322 -276 -718 -32 -82 -95 -247 -140 -365
-89 -233 -268 -697 -277 -717 -7 -16 116 -6 133 11 7 6 23 43 37 81 30 84 202
538 447 1175 45 118 113 296 150 395 38 99 71 188 75 198 6 15 0 17 -57 17
l-63 0 -29 -77z"/>
					<path d="M6070 9670 c0 -41 4 -60 13 -61 6 0 194 -1 417 -2 l405 -2 3 -787 2
-788 23 0 c12 0 81 7 152 15 72 8 240 25 375 39 439 45 724 77 728 81 2 2 3
325 2 718 l-1 715 78 7 c42 4 230 5 417 4 l341 -4 3 63 3 62 -1481 0 -1480 0
0 -60z m1770 -133 c0 -2 -63 -108 -140 -236 -77 -128 -140 -236 -140 -242 0
-5 6 -9 13 -9 34 1 327 -66 327 -75 0 -6 -8 -15 -17 -21 -10 -6 -180 -140
-377 -298 -197 -158 -361 -284 -364 -282 -2 3 10 29 29 58 135 219 249 413
246 421 -2 6 -76 25 -166 43 -89 18 -166 36 -169 39 -4 4 6 17 21 29 16 11
185 146 375 298 310 248 362 287 362 275z"/>
					<path d="M9260 8999 l0 -731 28 6 c40 8 407 46 448 46 19 0 36 -6 38 -12 4
-13 22 -193 76 -733 17 -170 33 -320 36 -332 3 -18 18 -26 72 -42 214 -62 357
-74 823 -68 497 6 612 23 826 127 205 100 315 245 385 511 22 83 22 92 25
1022 l4 937 -61 0 -60 0 0 -853 c0 -527 -4 -885 -10 -937 -43 -352 -210 -550
-534 -635 -137 -36 -274 -48 -556 -49 l-255 -1 -3 1238 -2 1237 -640 0 -640 0
0 -731z"/>
					<path d="M9450 8194 c-118 -13 -390 -42 -605 -64 -214 -22 -516 -54 -670 -70
-349 -37 -1203 -127 -1770 -185 -236 -25 -513 -54 -615 -65 -102 -11 -311 -33
-465 -50 -154 -16 -313 -33 -353 -36 -40 -4 -74 -10 -77 -13 -3 -7 6 -105 94
-936 28 -264 59 -563 70 -665 10 -102 38 -368 62 -592 l43 -408 41 0 c22 0 78
5 125 10 47 5 195 21 330 35 135 14 328 34 430 45 374 40 862 92 1140 120 157
16 329 34 383 40 53 5 141 15 195 20 53 6 201 21 327 35 127 13 318 34 425 45
107 11 389 41 625 65 616 65 777 84 784 90 8 9 11 -19 -189 1895 -23 217 -48
460 -56 540 -8 80 -17 151 -19 158 -5 14 14 15 -255 -14z m-402 -459 c8 -22
69 -170 134 -330 66 -159 208 -504 315 -765 107 -261 211 -512 230 -558 19
-45 33 -88 30 -95 -4 -12 -113 -26 -415 -55 l-52 -4 -39 83 c-21 46 -69 149
-106 229 -37 80 -70 148 -73 151 -7 6 -352 -27 -359 -35 -2 -2 -26 -109 -54
-237 -28 -129 -54 -241 -58 -249 -6 -11 -63 -21 -237 -41 -126 -14 -233 -24
-237 -21 -5 2 -6 14 -3 26 3 11 25 134 50 271 51 285 209 1149 260 1423 19
101 36 186 39 188 2 3 69 12 148 20 79 8 194 21 254 28 152 19 154 19 173 -29z
m-1418 -119 c0 -15 76 -731 112 -1056 29 -262 28 -395 -1 -480 -65 -185 -196
-299 -404 -352 -66 -17 -102 -19 -196 -16 -157 6 -253 42 -346 129 -79 74
-116 132 -149 230 -27 80 -63 327 -49 339 7 5 302 38 393 44 23 1 25 -3 32
-59 17 -133 27 -162 69 -199 33 -31 46 -36 87 -36 62 0 106 28 134 84 l21 45
-21 203 c-12 112 -36 336 -52 498 -17 162 -37 359 -46 437 -8 79 -13 147 -10
151 3 5 61 15 128 22 68 7 150 16 183 20 97 12 115 11 115 -4z m-1775 -201
c180 -46 317 -176 370 -352 8 -26 24 -136 35 -243 12 -107 32 -298 45 -425 29
-265 30 -333 10 -430 -22 -102 -59 -171 -134 -246 -101 -101 -213 -145 -393
-156 -119 -7 -199 7 -293 52 -146 70 -259 229 -291 408 -5 32 -4 37 14 37 29
0 313 30 344 36 24 5 30 0 52 -44 35 -69 70 -92 140 -92 52 0 60 3 97 38 53
49 60 84 47 241 -6 69 -12 128 -14 130 -2 2 -17 -9 -35 -23 -78 -66 -171 -86
-300 -67 -222 34 -365 189 -415 449 -64 332 97 617 389 688 74 18 260 17 332
-1z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
